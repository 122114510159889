import React from 'react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import VotingRadio from 'admin/components/VotingRadio'
import { VoterTypes } from 'admin/pages/Voters'
import { mobileCss } from 'utils/theme'

export interface VotingTableRowTypes {
  id: string
  sortingName: string
  type: string
  nominee: string
  nominatedBy: string
  nominatedByCompany: string
  tableComments?: string
}

interface VotingTableProps {
  candidates: VotingTableRowTypes[]
  voter?: VoterTypes
  onVote: (candidateId: string, score: string) => void
  votes: {
    [key: string]: {
      score: string
      nominee: string
    }
  }
}

const VotingTable = ({ candidates, voter, onVote, votes }: VotingTableProps) => {
  const voterCode = voter?.code || ''
  const navigate = useNavigate()
  const isSaved = voter?.voting_saved || false

  const sortedCandidates = candidates
    .slice() // Create a copy to avoid mutating the original array
    .sort((a, b) => (a.sortingName > b.sortingName ? 1 : -1))

  const handleRadioChange = (candidateId: string, selectedValue: string) => {
    onVote(candidateId, selectedValue)
  }

  return (
    <>
      {sortedCandidates.map(candidate => {
        return (
          <Wrapper key={candidate.id}>
            <CandidateName onClick={() => {
              navigate(`/kapitula/${voterCode}/${candidate.id}`)
            }}>{candidate?.nominee || '---'}
            </CandidateName>
            <FormWrapper>
              <VotingRadio
                disabled={isSaved}
                onRadioChange={(selectedValue) => handleRadioChange(candidate.id, selectedValue)}
                value={votes[candidate.id] ? votes[candidate.id]?.score : '0'}
              />
            </FormWrapper>
          </Wrapper>
        )
      })}
    </>
  )
}

export default VotingTable

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  gap: 8px;

  ${mobileCss(`
    flex-direction: column;
    margin-bottom: 20px;
    gap: 6px;
  `)};
`
const CandidateName = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  min-height: 46px !important;
  padding: 5px;
  text-align: center;

  font-size: 18px;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.primary};
  border: 1px solid ${({ theme }) => theme.colors.secondary};
  border-radius: 23px 0 0 23px;
  transition: all .3s;

  &:hover {
    background-color: ${({ theme }) => theme.colors.primaryLight};
  }
  
  ${mobileCss(`
    width: 100%;
    border-radius: 8px;
  `)};
`

const FormWrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.secondary};
  border-radius: 0 23px 23px 0;
  display: flex;
  align-items: center;
  overflow: hidden;
  height: 56px;
  padding: 0 16px;

  ${mobileCss(`
    width: 100%;
    border-radius: 8px;
    justify-content: center;
    padding: 0;
  `)};
`
