import { createClient } from '@supabase/supabase-js'

// PROD
const SUPABASE_URL = "https://uhzzqrcsjuzilbdkxmth.supabase.co"

// TEST
// const SUPABASE_URL = "https://nmgwfawrdzqfrqinmnxa.supabase.co"
const SUPABASE_KEY = process.env.REACT_APP_SUPABASE_KEY || ''



export const supabase = createClient(SUPABASE_URL, SUPABASE_KEY, {
  auth: {
    autoRefreshToken: true
  }
})
// alternate version, TS does not like it very much:
// export const supabase = createClient(SUPABASE_URL, process?.env?.REACT_APP_SUPABASE_KEY || '')
